.contact-details-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f0f0f0; /* Light gray background */
  border-radius: 0.65rem;
  border: 1.5px solid #000; /* Black border */
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.contact-details-card .icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: #000; /* Black background */
  margin-bottom: 0.8rem;
}

.icon img {
  width: 1.8rem;
  height: auto;
  object-fit: contain;
}

.icon.white img {
  background:#fff;
  border-radius: 7px;
}

.contact-details-card p {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
  color: #000; /* Black text color */
}

@media (max-width: 400px) {
  .contact-details-card p {
    font-size: 0.8rem;
  }
}
