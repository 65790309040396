.gallery-container{
  max-width: 1300px; 
  width: 100%;
  aspect-ratio: "10/6";
  margin: 0 auto
}

.gallery-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
  color: #fff; /* Black text color */
}