.certficate {
  background: #eee; /* Light gray background */
  border-radius: 0.65rem;
  border: 1.5px solid #000; /* Black border */
  padding: 1.5rem;
  margin: 0 1rem;
}

.certficate .background{
  position:relative;
  float:right;
  width:20%;
  margin-left:.5rem;
  opacity:.4;
}

.certficate h4,
.certficate h5,
.certficate p{
  color:#000;
} 

.certficate a:visited{
  color: darkolivegreen;
  text-decoration:line-through;
}

.certficate a:visited{
  color: darkolivegreen;
  text-decoration:line-through;
}

.certficate a,
.certficate a:hover, 
.certficate a:active{
  color:#779556;
  text-decoration:none;
}

.certficate a,
.certficate a:hover{
  color:#779556;
}


.certificate a:hover{
  text-decoration:underline;
}


.certficate p{
  padding:.4em 0;
  margin:.2em;
}

.certficate h4{
  font-size: 1.7rem;
  margin-bottom: .25rem;
}

.certficate h5{
  margin-bottom:.25rem;
}

.certficate h6 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
  color: #000; /* Black text color */
}

.certficate span{
  display:block;
  clear:left;
  width:100%;
}

.work-duration {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 400;
  background: #779556;
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  margin-bottom: 1.3rem;
  color: #000; /* Black text color */
}

.certficate ul li {
  list-style: none;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  position: relative;
  color: #000; /* Black text color */
}



.certimage {
  width:100%;
  overflow:hidden;
  position:relative;
}

.certficate ul li::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #000; /* Black bullet point color */
  border-radius: 0.5rem;
  position: absolute;
  left: -1.3rem;
  top: 0.35rem;
}

@media (max-width: 768px){
 
  .certficate {
    margin: 0;
  }

  .certficate h6 {
    font-size: 0.95rem;
  }

  .certficate ul li {
    font-size: 0.75rem;
  }
}
