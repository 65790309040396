.img-slider{
  width: 100%;
  height: 100%;
  position: relative;
}

.img-container{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display:flex;
}

.img-container h4, p.details{
  position: absolute;
  color:#000;
  z-index:3;
  padding:1rem;
}

.img-container h4{
  width: auto;
  background:#779556;
  border-radius: .3rem;
  top: 2px;
  left: 2px;
}

.img-container p.details{
  position:absolute;
  width: 100%;
  bottom: -40px;
  color:#fff;
  background: linear-gradient(rgba(0,0,0,.7), black);
  font-size: 0.8rem;
}
@media(min-width:768px){
.img-container p.details aside{
  float:right;
  clear:right;
  margin-top:-19px;
  font-weight: bold;
}
}


.img-container p.details a{
  color:#779556;
  text-decoration: none;
  font-weight:500;
}

.img-container p.details a:hover{
  text-decoration:underline;
}

.img-slider-img{
  object-fit: cover;
  width:100%;
  height: 100%;
  display:block; 
  flex-shrink: 0;
  flex-grow: 0;
  transition: translate 300ms ease-in-out;
}

.img-slider-btn{
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 100ms ease-in-out;
}

.img-slider-btn:hover,
.img-slider-btn:focus-visible {
  background-color:rgba(0,0,0, 0.2);
}

.img-slider-btn > * {
  color:#000;
  display:block;
  border: 1.5px solid #000;
  width: 2.2rem;
  height: 2.2rem;
  background: #eee;
  border-radius: 0.65rem;
}

.img-slider-btn-left{
  left:0;
  font-size: 2rem !important;
}

.img-slider-btn-right{
  right: 0;
  font-size: 2rem !important;
}

.img-slider-dot-btn{
  all:unset;
  display:block;
  cursor:pointer;
  width: 1rem;
  height: 1rem;
}

.img-slider-dot-btn:hover,
.img-slider-dot-btn:focus-visible{
  scale: 1.2;
}

.img-slider-dot-btn > *{
  stroke: white;
  fill: black;
  height:100%;
  width:100%;
}

.img-slider-dot-btn:focus-visible,
.img-slider-btn:focus-visible{
  outline: auto;
}

.skip-link{
  position:absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  border: 0;
  clip: rect(0,0,0,0);
}

.skip-link:focus-visible{
  top: 0;
  left:0;
  border: 1px solid black;
  background-color:white;
  clip: unset;
  padding:0.5rem;
  width: auto;
  height: auto;
  margin: 0;
  text-decoration: none;
  color:black;
  z-index: 100;
}