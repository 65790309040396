.education-card {
  background: #eee; /* Light gray background */
  border-radius: 0.65rem;
  border: 1.5px solid #000; /* Black border */
  padding: 1.5rem;
  margin: 0 1rem;
}

.education-card .background{
  position:relative;
  float:right;
  width:20%;
  opacity:.4;
}

.education-card h4,.education-card h5,.education-card p{
  color:#000;
} 

.education-card h4{
  font-size: 1.7rem;
  margin-bottom: .25rem;
}

.education-card h5{
  margin-bottom:.25rem;
}

.education-card h6 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
  color: #000; /* Black text color */
}

.education-card h4 a:visited{
  color: darkolivegreen;
  text-decoration:line-through;
}

.education-card h6 a,
.education-card h6 a:hover, 
.education-card h6 a:active{
  color:#779556;
  text-decoration:none;
}

.education-card h6 a,
.education-card h6 a:hover{
  color:#779556;
}


.education-card h6 a:hover{
  text-decoration:underline;
}


.work-duration {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 400;
  background: #779556;
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  margin-bottom: 1.3rem;
  color: #000; /* Black text color */
}

.education-card ul li {
  list-style: none;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  position: relative;
  color: #000; /* Black text color */
}

.education-card ul li::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #000; /* Black bullet point color */
  border-radius: 0.5rem;
  position: absolute;
  left: -1.3rem;
  top: 0.35rem;
}

@media (max-width: 768px){
 
  .education-card {
    margin: 0;
  }

  .education-card h6 {
    font-size: 0.95rem;
  }

  .education-card ul li {
    font-size: 0.75rem;
  }
}
