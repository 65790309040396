.skills-info-card {
  min-height: 23rem;
  border-radius: 0.65rem;
  border: 1.5px solid #000; /* Black border color */
  background: rgba(255, 255, 255, 0.8); /* White background with opacity */
  backdrop-filter: blur(1rem);
}

.skills-info-card h6 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.8rem 2rem;
  color: #000; /* Black text color */
  background: #e5e5e5; /* Light gray background color */
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1.5px solid #779556; /* Purple border color */
}

.skills-info-content {
  padding: 2rem;
}

.skill-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skill-info p {
  font-size: 1rem;
  font-weight: 500;
  color:#000;
}

.skill-info .perentage {
  color: #dd8cfa;
}

.skill-progress-bg {
  width: 100%;
  height: 0.5rem;
  background: #fff; /* Light gray background color */
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow: hidden;
}

.skill-progress {
  width: 0%;
  height: 0.5rem;
  background: linear-gradient(90deg, #779556 -50%, #779556 50%);
  border-radius: 0.5rem;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 1025px) {
  .skills-info-card h6 {
    font-size: 1.1rem;
  }

  .skill-info p {
    font-size: 0.9rem;
  }

  .skills-info-content {
    padding: 1.5rem;
  }
}
