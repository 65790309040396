.skills-card{
  border-radius: 0.65rem;
  border: 1.5px solid #000; /* Black border color */
  padding: 2.5rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.8); /* White background with opacity */
  backdrop-filter: blur(1rem);
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.skills-card:hover,
.skills-card.active{
  background: #f0f0f0; /* Light gray background on hover */
}

.skills-card span{
  font-size: 1.3rem;
  font-weight: 500;
  color: #000; /* Black text color */
}

.skill-icon{
  width: 4rem;
  height: 4rem;
  display:flex;
  align-items: center;
  justify-content: center;
  background: #000; /* Black background color */
  border-radius: 0.65rem;
  border: 1.5px solid #000; /* Black border color */
  position:absolute;
  top: -1rem;
  left: -1rem;
}

.skill-icon img{
  width: 2.4rem;
  padding:2px;
  height:auto;
  object-fit: contain;
  background:#fff;
  border-radius: 7px;
}

@media(max-width: 1025px){
  .skills-card{
    padding: 2rem;
  }
  .skills-card span{
    font-size: 1rem;
  }
  .skill-icon {
    width: 3rem;
    height: 3rem;
  }
  .skill-icon img{
    width: 1.8rem;
  }
}

@media(max-width:768px){
  .skills-card span{
    font-size: 0.9rem;
  }
}
