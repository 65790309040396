@import url('https://fonts.google.com/css2?family=Poppins:wght@400;500;600&display=swap');
* {
  font-family: "Poppins", sans-serif;
  margin:0;
  box-sizing: border-box;
}

body{
  background-color:#060417;
  color:#fff;
}