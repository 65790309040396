.work-experience-card {
  background: #eee; /* Light gray background */
  border-radius: 0.65rem;
  border: 1.5px solid #000; /* Black border */
  padding: 1.5rem;
  margin: 0 1rem;
}

.work-experience-card .background{
  display:block;
  float:right;
  width: 20%;
  opacity:.5;
}

.work-experience-card h5 {
  font-weight: 500;
  margin-bottom: 0.7rem;
  color: #000; /* Black text color */
}

.work-experience-card h6 {
  font-size: 1.05rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
  color: #000; /* Black text color */
}

.work-duration {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 400;
  background: #779556;
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  margin-bottom: 1.3rem;
  color: #000; /* Black text color */
}

.work-experience-card ul li {
  list-style: none;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  position: relative;
  color: #000; /* Black text color */
}

.work-experience-card ul li::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #000; /* Black bullet point color */
  border-radius: 0.5rem;
  position: absolute;
  left: -1.3rem;
  top: 0.35rem;
}

@media (max-width: 768px){
 
  .work-experience-card {
    margin: 0;
  }

  .work-experience-card h6 {
    font-size: 0.95rem;
  }

  .work-experience-card ul li {
    font-size: 0.75rem;
  }
}
